import "./scss/global-styles.scss";
import style from "./scss/style.module.scss";
import CssBaseline from "@material-ui/core/CssBaseline";
import React, { Component, useMemo, useState } from "react";
import {
  ThemeProvider,
  createMuiTheme,
  StylesProvider,
  makeStyles,
  SimplePaletteColorOptions,
} from "@material-ui/core/styles";
import classNames from "classnames";
import { UIView } from "@uirouter/react";
import { IAppProps } from "./container";
import MainHeaderContainer from "app/components/MainHeader/container";
import { loadStatus, loaderType } from "app/types/common.types";
import ErrorPanelContainer from "app/components/ErrorPanel/container";
import ProgressStepperContainer from "app/components/ProgressStepper/container";
import SetupContainer from "app/components/Setup/container";
import { themeTypes, IWidgetTheme, modeType } from "app/models";
import { createStyles, withStyles } from "@material-ui/styles";
import { Fab } from "@material-ui/core";
import { renderIf } from "app/services/utils/utils.service";
import { IStateFromProps } from "./types";
import LoaderOverlayContainer from "app/components/LoaderOverlay/container";
import { LocationService } from "app/services/location/location.service";
import { IntlProvider } from "react-intl/dist";
import { IntlService } from "app/services/intl/intlService";
import { ThemeColorsService } from "app/services/theme/themeColors.service";
import { IframeResizerService } from "app/services/iframeResizer/iframeResizer.service";
import PhoneNumberService from "shared-services/phone-number-service/index";
import { ColorPartial } from "@material-ui/core/styles/createPalette";

const NS = "App";

PhoneNumberService.loadLibPhoneNumber();

export const themeStyles = (theme: IWidgetTheme) => {
  const { textColor1, textColor2, colorError, colorWarning, colorSuccess } =
    ThemeColorsService.getCommonThemeStyles(theme);

  return createStyles({
    "@global": {
      a: {
        color: textColor2,
      },
      ".error-text": {
        color: colorError,
      },
      ".error-bg": {
        backgroundColor: colorError,
      },
      ".success-text": {
        color: colorSuccess,
      },
      ".warning-text": {
        color: colorWarning,
      },
      ".primary-text": {
        color: textColor1,
      },
      ".secondary-text": {
        color: textColor2,
      },
      ".dark-text": {
        color: "#fff",
      },
    },
  });
};

const useStyles = makeStyles((theme: IWidgetTheme) => {
  const { secondary, textColor1 } =
    ThemeColorsService.getCommonThemeStyles(theme);
  const isLight =
    theme.type === themeTypes.light || theme.type === themeTypes.outlinedLight;
  return createStyles({
    mainContainer: {
      color: isLight ? "#4d4d4d" : "white",
    },
  });
});

/**
 * Had to separate main content so that global styles could be created using the theme's colors
 */
function MainContent({
  appLoadStatus,
  appSettings,
  isStepRoute,
  appLoaderType,
  stripe,
  wrapperStyle,
  theme,
}: IStateFromProps) {
  const isLoadingWithOverlay =
    appLoadStatus === loadStatus.loading &&
    appLoaderType === loaderType.overlayContent;
  const isLoadingAsHidden =
    appLoadStatus === loadStatus.loading &&
    appLoaderType === loaderType.hideContent;

  const classes = useStyles({ theme });

  return (
    <>
      <MainHeaderContainer />
      <main className={[style.mainContainer, classes.mainContainer].join(" ")}>
        {renderIf(isLoadingAsHidden, <SetupContainer />)}
        {renderIf(isLoadingWithOverlay, <LoaderOverlayContainer />)}
        {renderIf(appLoadStatus === loadStatus.failed, <ErrorPanelContainer />)}

        {renderIf(
          appLoadStatus === loadStatus.success || isLoadingWithOverlay,
          <div>
            {renderIf(
              isStepRoute && !appSettings.isStyleGuide,
              <ProgressStepperContainer />
            )}

            <UIView />
          </div>
        )}
      </main>
    </>
  );
}

/**
 * Can be used for any paage that needs theming. Should not include any redux 'containers', just in case
 * it gets used for a simple instance that doesn't need redux (such as terms and conditions).
 */
export function AppWrap({
  theme,
  children,
}: {
  theme: IWidgetTheme;
  children: any;
}) {
  const StyleWrap = withStyles(themeStyles)(
    ({ children }: { children: any }) => <>{children}</>
  );

  const isDark =
    theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;

  const BookedByBtnColors: {
    contained: {
      backgroundColor: string;
      color: string;
    };
    outlined: {
      borderColor?: string;
      color?: string;
    };
    outlinedHover: {
      backgroundColor?: string;
    }
  } = useMemo(() => {
    const color = theme.defaultColors
      ? (theme.palette.primary as ColorPartial)[500]
      : (theme.palette.primary as SimplePaletteColorOptions).main;

    return {
      contained: isDark
        ? {
            backgroundColor: color,
            color: "white",
          }
        : {
            backgroundColor: color,
            color: "white",
          },
      outlined: isDark
        ? {
            borderColor: "white",
            color: "white",
          }
        : {},
        outlinedHover: {
          backgroundColor: `${color}80`,
        }
    };
  }, [isDark, theme]);

  const muiTheme = createMuiTheme({
    ...theme,
    overrides: {
      MuiCheckbox: isDark
        ? {
            colorSecondary: {
              "&$checked": {
                color: "#fff",
              },
            },
          }
        : {},
      MuiRadio: isDark
        ? {
            colorSecondary: {
              "&$checked": {
                color: "#fff",
              },
            },
          }
        : {},
      MuiFormLabel: isDark
        ? {
            root: {
              "&$focused": {
                color: "#fff",
              },
            },
          }
        : {},
      MuiInput: isDark
        ? {
            underline: {
              "&:after": {
                borderBottom: "2px solid #fff",
              },
            },
          }
        : {},
      MuiButton: {
        root: {
          "&.MuiButton-contained.bookedByBtn": BookedByBtnColors.contained,
          "&.MuiButton-outlined.bookedByBtn": BookedByBtnColors.outlined,
          "&.MuiButton-outlined.bookedByBtn:hover": BookedByBtnColors.outlinedHover,
        },
      },
    },
  });

  return (
    <div>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        {/* using StylesProvider/injectFirst, styled components can override Material-UI's styles */}
        <StylesProvider injectFirst>
          <StyleWrap>
            {/* IntlProvider is used for currency formatting (but more could be added, such as language) */}
            <IntlProvider locale={IntlService.getLocale()}>
              {children || null}
            </IntlProvider>
          </StyleWrap>
        </StylesProvider>
      </ThemeProvider>
    </div>
  );
}

export class App extends Component<IAppProps & IStateFromProps> {
  componentDidMount() {
    this.props.startLoading();
    LocationService.setBgColor(this.props.appSettings.theme);
  }

  render() {
    return (
      <AppWrap theme={this.props.theme}>
        <MainContent {...this.props} />
      </AppWrap>
    );
  }
}
