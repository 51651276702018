import { of, interval, Observable } from 'rxjs';
import { takeWhile, switchMap } from 'rxjs/operators';
import { phoneNumberTypes, ILibPhoneNumber, IParsedPhoneNumber } from './phoneNumber.types';
import { CountriesWithCode } from './country';
import { IVenue, AustralianStates, ICustomer } from 'shared-types/index';

const NS = 'PhoneNumberService';

export type Countries = Record<string, any>;

const countryLabels: Countries = {};
CountriesWithCode.forEach((c) => {
  countryLabels[c.code] = {
    displayCode: c.code + ' ' + c.dial_code,
    dial_code: c.dial_code,
  };
});

const OrderedCountryCode = CountriesWithCode.sort(
  (a, b) => b.dial_code.length - a.dial_code.length
);
export default class PhoneNumberService {
  static loadLibPhoneNumber(): Observable<ILibPhoneNumber> {
    const scriptEl = document.createElement('script');
    scriptEl.src = '/other-scripts/libphonenumber-js/libphonenumber-max.js';
    document.body.appendChild(scriptEl);
    return interval(1000).pipe(
      takeWhile((i: number) => !this.getLib() && i < 20, true), // try for 20 seconds - When the optional inclusive parameter is set to true it will also emit the first item that didn't pass the predicate.
      switchMap((i: number) => {
        return of(this.getLib());
      })
    );
  }

  static isNotPhoneNumberType(value: string): boolean {
    if (!value) return false;
    const format = /[a-zA-Z`!@#$%^&*_=\[\]{};':"\\|,<>\/?~]/;
    return format.test(value);
  }

  /**
   * Takes a phone number as a string and validates it.
   * @param phoneNumber phone number as string
   * @param countryCode ISO country code, such as 'AU', 'US', etc
   * @param checkValidity optionally pass `true` to do `isValid` check
   * @param type format type. Defaults to `INTERNATIONAL`, but can accept other values as well, such as `NATIONAL`.
   * @returns a phone number or null for invalid
   */
  static formatNumber(
    phoneNumber: string,
    countryCode: string,
    checkValidity: boolean,
    type: phoneNumberTypes = phoneNumberTypes.INTERNATIONAL,
    newVersion = false
  ): string {
    const libphonenumber: ILibPhoneNumber = this.getLib();

    if (!libphonenumber) {
      console.warn(NS, "Library 'libphonenumber' has not loaded yet.");
      return null;
    }

    if (!phoneNumber) {
      console.warn('Phone number is not defined');
      return null;
    }

    if (this.isNotPhoneNumberType(phoneNumber)) {
      console.warn('Phone number is not type of number type');
      return null;
    }

    const parsedPhoneNumber: IParsedPhoneNumber = libphonenumber.parsePhoneNumberFromString(
      phoneNumber,
      countryCode
    );

    if (!parsedPhoneNumber || (checkValidity && !parsedPhoneNumber.isValid())) {
      return null;
    }

    if (countryCode && (parsedPhoneNumber as any).country !== countryCode) return null;
    if (
      newVersion &&
      '+' + (parsedPhoneNumber as any).countryCallingCode !==
        this.getCountryByCountryCode(countryCode).dial_code
    )
      return null;

    return parsedPhoneNumber.format(type);
  }

  /**
   *
   * @param phoneNumber
   * @param countryCode
   * @param type
   */
  static parsePhoneNumberFormat(
    phoneNumber: string,
    countryCode: string,
    type: phoneNumberTypes = phoneNumberTypes.INTERNATIONAL
  ): string {
    const libphonenumber: ILibPhoneNumber = this.getLib();
    const parsedPhoneNumber: IParsedPhoneNumber = libphonenumber.parsePhoneNumberFromString(
      phoneNumber,
      countryCode
    );
    return parsedPhoneNumber.format(type);
  }

  static parseNumber(phoneNumber: string, newVersion = false): any {
    const libphonenumber: ILibPhoneNumber = this.getLib();

    if (!libphonenumber) {
      console.warn(NS, "Library 'libphonenumber' has not loaded yet.");
      return null;
    }

    if (!phoneNumber) {
      console.warn('Phone number is not defined');
      return null;
    }

    try {
      const parsedNumber: any = libphonenumber.parsePhoneNumber(phoneNumber);
      if (!newVersion) return parsedNumber;

      if (this.isNotPhoneNumberType(phoneNumber)) {
        const dial_code = this.getCountryByCountryCode(parsedNumber.country)?.dial_code;
        if (!dial_code) throw new Error(); // when parsedNumber can't has country
        const nationalNumber = phoneNumber.replace(dial_code, '');
        const country = parsedNumber.country;
        return {
          nationalNumber,
          country,
        };
      } else {
        const dial_code = this.getCountryByCountryCode(parsedNumber.country)?.dial_code;
        if (!dial_code) throw new Error(); // when parsedNumber can't has country, in some case, parsedNumber could have no dial code
        const nationalNumber = parsedNumber.number.replace(dial_code, '');
        const country = parsedNumber.country;
        return {
          nationalNumber,
          country,
        };
      }
    } catch (error) {
      return this.getManualParseNumber(phoneNumber);
    }
  }

  static getManualParseNumber(phoneNumber: string): any {
    const manualCountry = OrderedCountryCode.find((c) => {
      return phoneNumber.indexOf(c.dial_code) === 0;
    });

    if (manualCountry) {
      const dial_code = manualCountry.dial_code;
      const nationalNumber = this.stripPhoneFormatting(phoneNumber.replace(dial_code, ''));
      const country = manualCountry.code;
      return {
        nationalNumber,
        country,
      };
    } else {
      return phoneNumber;
    }
  }

  static getInternationPhoneNumberWithoutPrefix(
    phoneNumberWithPrefix: string,
    countryCode: string
  ): string {
    if (!phoneNumberWithPrefix) return phoneNumberWithPrefix;
    if (!countryCode) return phoneNumberWithPrefix;
    const dial_code: string = countryLabels[countryCode].dial_code;
    const phoneNumberWithoutPrefix = phoneNumberWithPrefix.replace(dial_code, '').trim();
    return phoneNumberWithoutPrefix;
  }

  static formatInterNationalPhoneNumber(phoneNumber: string, countryCode: string): string {
    try {
      if (!countryCode) return phoneNumber;

      if (!phoneNumber) {
        console.warn('Phone number is not defined');
        return phoneNumber;
      }

      const dial_code: string = countryLabels[countryCode].dial_code;
      let formattedNumber: string = phoneNumber;
      if (phoneNumber.indexOf(dial_code) < 0) formattedNumber = dial_code + ' ' + phoneNumber;

      return formattedNumber;
    } catch (error) {
      console.warn('formatInterNationalPhoneNumber error', error);
      return phoneNumber;
    }
  }

  /**
   * Adds state prefix to Australian landline phone numbers
   */
  static checkPhoneState(
    phoneNumber: string,
    country: string,
    state: string,
    removeSpacesAndHyphens = false
  ): string {
    if (removeSpacesAndHyphens) phoneNumber = phoneNumber.split(' ').join('').split('-').join('');
    if (8 === phoneNumber.length && '0' !== phoneNumber.charAt(0) && 'AU' === country) {
      let stateCode;
      switch (state) {
        case AustralianStates.VIC:
          stateCode = '03';
          break;
        case AustralianStates.TAS:
          stateCode = '03';
          break;
        case AustralianStates.QLD:
          stateCode = '07';
          break;
        case AustralianStates.WA:
          stateCode = '08';
          break;
        case AustralianStates.SA:
          stateCode = '08';
          break;
        case AustralianStates.NT:
          stateCode = '08';
          break;
        default:
          stateCode = '02';
      }
      phoneNumber = stateCode + phoneNumber;
    }
    return phoneNumber;
  }

  static normalizePhoneNumber(
    phoneNumber: string,
    format: phoneNumberTypes,
    venue: { country: string; state: string }
  ): string {
    if (phoneNumber) {
      phoneNumber = this.checkPhoneState(phoneNumber, venue.country, venue.state, true);
      return this.formatNumber(phoneNumber, venue.country, true, format);
    }
    return '';
  }

  static stripPhoneFormatting(phoneNumber: string): string {
    return phoneNumber ? phoneNumber.replace(/\s/g, '').replace(/-/g, '') : '';
  }

  static getCountryByDialCode(code: string): { name: string; dial_code: string; code: string } {
    return CountriesWithCode.find((c) => c.dial_code === code);
  }

  static getCountryByCountryCode(code: string): { name: string; dial_code: string; code: string } {
    return CountriesWithCode.find((c) => c.code === code);
  }

  static resetPhoneNumberWithTypeValidation(customer: ICustomer): ICustomer {
    if (!customer.phone) return customer;
    const tempPhone = this.parseNumber(customer.phone, true);
    if (tempPhone && typeof tempPhone !== 'string') {
      if (this.isNotPhoneNumberType(tempPhone.nationalNumber)) {
        customer.phone = tempPhone.nationalNumber;
      }
    }
    return customer;
  }
  static parsePhoneNumber(phoneNumber: string): any {
    try {
      return window.libphonenumber.parsePhoneNumber(phoneNumber);
    } catch (e) {
      return null;
    }
  }

  private static getLib(): ILibPhoneNumber {
    return (window as any).libphonenumber;
  }
}
